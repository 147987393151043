import { useState, useEffect } from "react";

import './Home.css';

import Category from "components/Category";
import SlideShow from "components/SlideShow";

import Page_Product from "page/Product";

export default function Page_Home(props){

    return(
        <div className="Page_Home">

            <SlideShow setLoading={ props.setLoading } />

            <Category setLoading={ props.setLoading } />

            <Page_Product setLoading={ props.setLoading } />
        </div>
    )
}
import { useState, useEffect } from "react";

import './Category.css';

import { SvgCat, SvgDog, SvgBird, SvgFish, SvgReptile, SvgRodent, SvgAdd, SvgRodent_hover, SvgReptile_hover, SvgFish_hover, SvgBird_hover, SvgCat_hover, SvgDog_hover } from "components/SvgFile";

export default function Category(props){

    const ColorIcon = '#3E3F6C';

    const [ listCategory, setListCategory ] = useState([
        {
            "type": "dog",
            "icon": <SvgDog className="icons" color={ ColorIcon } />,
            "hover": <SvgDog_hover className="icons" color={ ColorIcon } />,
            "title": "Cachorros"
        },
        {
            "type": "cat",
            "icon": <SvgCat className="icons" color={ ColorIcon } />,
            "hover": <SvgCat_hover className="icons" color={ ColorIcon } />,
            "title": "Gatos"
        },
        {
            "type": "birds",
            "icon": <SvgBird className="icons" color={ ColorIcon } />,
            "hover": <SvgBird_hover className="icons" color={ ColorIcon } />,
            "title": "Aves"
        },
        {
            "type": "fish",
            "icon": <SvgFish className="icons" color={ ColorIcon } />,
            "hover": <SvgFish_hover className="icons" color={ ColorIcon } />,
            "title": "Peixes"
        },
        {
            "type": "reptile",
            "icon": <SvgReptile className="icons" color={ ColorIcon } />,
            "hover": <SvgReptile_hover className="icons" color={ ColorIcon } />,
            "title": "Répteis"
        },
        {
            "type": "rodent",
            "icon": <SvgRodent className="icons" color={ ColorIcon } />,
            "hover": <SvgRodent_hover className="icons" color={ ColorIcon } />,
            "title": "Roedores"
        }
    ]);

    return(
        <div className="Category page_index">
            <div className="container div_list_category">
                <div className="list_category">
                    {
                        listCategory.map((elem, index)=>{
                            return(
                                <div className="div_category" key={ index } style={ { border: "2px solid " + ColorIcon } }>
                                    <div className="div_icon_hover">
                                        { elem.hover }
                                    </div>
                                    <div className="div_icon">
                                        { elem.icon }
                                    </div>
                                    <div className="title" style={ { color: ColorIcon } }>{ elem.title }</div>
                                </div>
                            )
                        })
                    }

                    <div className="div_category div_end" style={ { border: "2px solid " + ColorIcon } }>
                        <div className="div_icon">
                            <SvgAdd className="icons icons_add" color={ ColorIcon } />
                        </div>
                        <div className="title" style={ { color: ColorIcon } }>Todas categorias</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
import { useState } from "react";

import './Notification.css';

export default function Notification(props){

    return(
        <div className="Notification">
            <div className="notification_title">
                { props.title }
            </div>
        </div>
    )
}
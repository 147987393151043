import { useState, useEffect } from "react";

import './Product.css';

import { GetDataPage } from "interface/Data";

import { SvgHeart, SvgHeart_empty } from "components/SvgFile";
import Notification from "components/Notification";

export default function Page_Product(props){

    const [ product, setProduct ] = useState(GetDataPage('product'));
    const [ status, setStatus ] = useState(false);
    const [ count, setCount ] = useState(0);

    function HandleData(id, index, index_1, value){
        const newData = [...product];
        newData[index]['list'][index_1]['status'] = value;
        setProduct(newData);
        
        setStatus(value);
        setCount(1);
    }

    function CountShowNotification(value){
        if(value !='hide'){
            setTimeout(() => {
                setCount(0);
            }, 1500);

            return <Notification title={ value ? "Adicionado aos favoritos" : "Removido dos favoritos" } />
        }
        return '';
    }

    useEffect(()=>{
        CountShowNotification(status);
    }, [product]);

    return(
        <div className="Page_Product">
            <div className="container div_product">
                {
                    product.map((elem, index)=>{
                        return(
                            <div className="show_product" key={ index }>
                                <div className="title">{ elem.title }</div>
                                <div className="list_product">
                                    {
                                        elem.list.map((elem_1, index_1)=>{
                                            return(
                                                <div className="show_details" key={ index_1 }>
                                                    <div className="div_img">
                                                        <img alt="img_" src={ elem_1.img } className="img" />
                                                    </div>
                                                    <div className="div_details">
                                                        <div className="cash">
                                                            <div className="show_cash">                                                                
                                                                { elem_1.promotion == "" ? null : "De " }
                                                                <span className={ elem_1.promotion == "" ? "" : "show_promotion" }>
                                                                    R$ { elem_1.cash }
                                                                </span>
                                                            </div>
                                                            <div className="add_favorite" onClick={ ()=>{ HandleData(elem_1.id, index, index_1, !elem_1.status) } }>
                                                                {
                                                                    elem_1.status ?
                                                                    <SvgHeart className="icons" color="#8FD437" />
                                                                    :
                                                                    <SvgHeart_empty className="icons" color="#8FD437" />
                                                                }
                                                            </div>
                                                        </div>
                                                        {
                                                            elem_1.promotion == "" ? null : 
                                                            <div className="promotion">
                                                                Por R$ { elem_1.promotion }
                                                            </div>
                                                        } 
                                                        <div className="title_product">{ elem_1.title }</div>
                                                        <div className="text">{ elem_1.text }</div>
                                                    </div>
                                                    <div className="btn">Adicionar ao carrinho</div>
                                                    <div className="inf_product">+ detalhes</div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            {
                count == 0 ? null : CountShowNotification(status)
            }
        </div>
    )
}
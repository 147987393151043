import { useState, useEffect } from "react";

import './Header.css';

import { SvgHeart, SvgPurchase, SvgSearch } from "components/SvgFile";

export default function Header(props){

    return(
        <div className="Header page_index">
            <div className="alert">Clique aqui para visualizar nossas promoções</div>
            <div className="container div_search_logo">
                <div className="div_logo">
                    <img alt="logotipo" src="./assets/logo.png" className="logo" />
                </div>
                <div className="div_search">
                    <div className="div_show_search">
                        <input type="text" className="input_search" placeholder="O que seu pet precisa?" />
                        <SvgSearch className="icons" color="#8FD437" />
                    </div>
                </div>
                <div className="div_purchase">
                    <div className="">
                        <SvgHeart className="icons" color="#8FD437" />
                    </div>
                    <div className="">
                        <SvgPurchase className="icons" color="#8FD437" />
                    </div>
                </div>
            </div>
        </div>
    )
}
import { useState, useEffect } from "react";

import './Footer.css';
import { SvgLocation, SvgPhone } from "components/SvgFile";

export default function Footer(props){

    return(
        <div className="Footer page_index">
            <div className="container">
                <div className="div_img_example">
                    <div className="div_cat">
                        <img alt="cat" src="./assets/cat.png" className="img_example" />
                    </div>
                    <div className="div_dog">
                        <img alt="dog" src="./assets/dog.png" className="img_example" />
                    </div>
                </div>
                
                <div className="show_div_address">
                    <div className="div_address">
                        <div className="show_data">
                            <div className="title">Endereço - Filial 1</div>
                        </div>

                        <div className="show_data">
                            <div className="div_icons">
                                <SvgLocation className="icons" color="#3E3F6C" />
                            </div>
                            <div className="address">
                                Ao contrário do que se acredita, Lorem Ipsum não é simplesmente um texto randômico.
                            </div>
                        </div>
                        
                        <div className="show_data">
                            <div className="div_icons">
                                <SvgPhone className="icons" color="#3E3F6C" />
                            </div>
                            <div className="address">
                                (11) 11111-1111
                            </div>
                        </div>
                    </div>

                    <div className="div_address">
                        <div className="show_data">
                            <div className="title">Endereço - Filial 2</div>
                        </div>

                        <div className="show_data">
                            <div className="div_icons">
                                <SvgLocation className="icons" color="#3E3F6C" />
                            </div>
                            <div className="address">
                                Ao contrário do que se acredita, Lorem Ipsum não é simplesmente um texto randômico.
                            </div>
                        </div>
                        
                        <div className="show_data">
                            <div className="div_icons">
                                <SvgPhone className="icons" color="#3E3F6C" />
                            </div>
                            <div className="address">
                                (11) 11111-1111
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
import { useState, useEffect } from "react";

import './SlideShow.css';

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import { GetDataPage } from "interface/Data";

export default function SlideShow(props){

    const [ status, setStatus ] = useState(false);
    const [ slideShow, setSlideShow ] = useState(GetDataPage('slide_show'));

    function AltSlide(value){
        setStatus(value);
        if(value){
            document.querySelector('.react-slideshow-container').style.maxWidth = "initial";
        }else {
            document.querySelector('.react-slideshow-container').style.maxWidth = "1200px";
        }
    }

    return(
        <div className="SlideShow page_index">
            <div className="div_temp" onClick={ ()=>{ AltSlide(!status) } }>
                Slide show - Modelo { status ? 1 : 2 }
            </div>
            <Slide>
                {
                    slideShow.map((elem, index)=>{
                        return(
                            <div className="each-slide-effect" key={ index }>
                                <div style={ { backgroundImage: 'url("' + elem.img + '")' } } />
                            </div>
                        )
                    })
                }
            </Slide>
        </div>
    )
}
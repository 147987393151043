import { useState, useEffect } from "react";

import Footer from "components/Footer";
import Header from "components/Header";
import Category from "components/Category";
import SlideShow from "components/SlideShow";

import { GetListPag, RegisterListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage } from "interface/Data";

import Page_Product from "page/Product";
import Page_Home from "page/Home";

export default function App(props) {

    const [ loading, setLoading ] = useState(false);
    const [ page, setPage ] = useState(GetListPag('page'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('home'));

    function CheckedPage(){
        switch (page) {
            case "index":
                return <Page_Home setLoading={ setLoading } />;
        }
    }

    useEffect(()=>{
        RegisterListPag('page', setPage);
        RegisterDataPage('home', setDataPage);
    }, []);

    return(
        <>
            <div className="bg_paw" style={ { backgroundImage: 'url("./assets/bg.png")' } } />
                
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <div aria-label="Loading" role="img" className="wheel-and-hamster">
                    <div className="wheel" />
                    <div className="hamster">
                        <div className="hamster__body">
                            <div className="hamster__head">
                                <div className="hamster__ear" />
                                <div className="hamster__eye" />
                                <div className="hamster__nose" />
                            </div>
                            <div className="hamster__limb hamster__limb--fr" />
                            <div className="hamster__limb hamster__limb--fl" />
                            <div className="hamster__limb hamster__limb--br" />
                            <div className="hamster__limb hamster__limb--bl" />
                            <div className="hamster__tail" />
                        </div>
                    </div>
                    <div className="spoke" />
                </div>                
            </div>
            <Header setLoading={ setLoading } />
            
            { CheckedPage() }

            <Footer setLoading={ setLoading } />
        </>
    );
}
import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import Axios from 'axios';

import { SetListData } from 'interface/Data';

const root = ReactDOM.createRoot(document.getElementById('root'));

const data = new FormData();
data.append('type_page', 'show_data_initial');

Axios({
    url     : process.env.REACT_APP_API_URL + 'data_page.json',
    mode    : 'no-cors',
    method  : 'POST',
    data    : data
})
.then(response => {
    console.log(response.data);
    SetListData(response.data);

    root.render(
        <React.Fragment>
            <App />
        </React.Fragment>
    );
});